import React from "react";

export const Checkout = () => {
  return (
    <div className="m-2">
      <div className="flex justify-center m-3 font-mono text-2xl font-bold">
        Checkout
      </div>
      <div className="grid sm:grid-cols-4 grid-cols-2 gap-3">
        <div className="hidden sm:block"></div>
        <div>
          <div className="my-3 font-mono text-xl">Booking Details</div>

          <div className="grid grid-cols-3 gap-1 border rounded border-black p-3">
            <div className="font-sans text-base">Name</div>
            <div className="col-span-2 font-sans text-base">
              Restaurant name here
            </div>
            <div className="font-sans text-base">Seats</div>
            <div className="col-span-2 font-sans text-base">S1, S3, S4</div>
            <div className="font-sans text-base">Amount</div>
            <div className="col-span-2 font-sans text-base text-red-500">
              $ 100.00
            </div>
          </div>
        </div>
        <div>
          <div className="my-3 font-mono text-xl">Personal Details</div>
          <input
            type="email"
            className="border rounded border-black  w-[100%]  mb-3 px-2"
            placeholder="Name"
          />
          <input
            type="email"
            className="border rounded border-black w-[100%] mb-3 px-2"
            placeholder="Email"
          />
          <input
            type="email"
            className="border rounded border-black  w-[100%]  mb-3 px-2"
            placeholder="Phone"
          />
          <div className="my-3 font-mono text-xl">Card Details</div>
          <input
            type="email"
            className="border rounded border-black  w-[100%]  mb-3 px-2"
            placeholder="Card Number"
          />
          <input
            type="email"
            className="border rounded border-black w-[100%] mb-3 px-2"
            placeholder="Expire Date"
          />
          <input
            type="email"
            className="border rounded border-black  w-[100%]  mb-3 px-2"
            placeholder="secrete"
          />
          <div className="flex justify-center">
            <div className="rounded-full bg-yellow-500 flex justify-center py-2 my-2 items-center w-full sm:w-[50%]">
              Pay
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
