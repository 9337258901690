import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { TimepickerUI } from "timepicker-ui";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClockFour,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import ImageMarker, { Marker } from "react-image-marker";
import { ISeat } from "../types/ISeat";
import { Link } from "react-router-dom";

export const DateSelection = () => {
  const [value, onChange] = useState(new Date());
  const tmRef = useRef(null);
  const [inputValue, setInputValue] = useState("12:00 PM");
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const inputFile = useRef<HTMLInputElement | null>(null);

  const importedSeats: Array<ISeat> = [
    {
      id: 1,
      top: 43.301693491749745,
      left: 58.36849507735584,
    },
    {
      id: 2,
      top: 51.271747865939744,
      left: 48.66385372714487,
    },
    { id: 3, top: 57.9443515280523, left: 39.24050632911393 },
    {
      id: 4,
      top: 66.84115641086905,
      left: 29.11392405063291,
    },
    {
      id: 5,
      top: 77.77681241266463,
      left: 42.33473980309423,
    },
    {
      id: 6,
      top: 68.32395722467183,
      left: 52.88326300984529,
    },
    {
      id: 7,
      top: 54.051999391819976,
      left: 18.565400843881857,
    },
    {
      id: 8,
      top: 48.67684644178486,
      left: 23.769338959212376,
    },
  ];

  const testHandler = useCallback((e: CustomEvent) => {
    setInputValue(`${e.detail.hour}:${e.detail.minutes} ${e.detail.type}`);
  }, []);

  useEffect(() => {
    const tm = tmRef.current as unknown as HTMLDivElement;

    const newPicker = new TimepickerUI(tm, {});
    newPicker.create();

    //@ts-ignore
    tm.addEventListener("accept", testHandler);
    return () => {
      //@ts-ignore
      tm.removeEventListener("accept", testHandler);
    };
  }, [testHandler]);

  // image maker
  const [seats, setSeats] = useState<Array<ISeat>>([
    { ...importedSeats[0], booked: true },
  ]);

  const CustomMarker = (props: any) => {
    return (
      <div
        className="sm:w-10 sm:h-10 w-5 h-5  rounded justify-center flex items-center"
        style={{ backgroundColor: props.booked ? "#F7430E" : "#2669F9" }}
        onClick={() => {
          if (!props.booked) {
            setSeats(seats.filter((seat: ISeat) => seat.id !== props.id));
          }
        }}
      >
        B
      </div>
    );
  };

  return (
    <div className="justify-center flex">
      <div>
        <div className="flex justify-center m-3 font-mono text-2xl font-bold">
          Restaurant Reservation System
        </div>
        <div className="rounded-full bg-orange-300 flex justify-center py-2 mt-10 m-5 items-center">
          <div
            onClick={() => {
              setIsCalendarVisible(!isCalendarVisible);
            }}
          >
            <FontAwesomeIcon className="px-1" icon={faCalendarAlt} />
            {moment(value).format("ddd, DD-MMM") + " || "}
          </div>
          <div
            onClick={() => {
              inputFile?.current?.click();
            }}
          >
            <FontAwesomeIcon className="px-1" icon={faClockFour} />
            {" " + inputValue + " || " + " "}
          </div>
          <FontAwesomeIcon className="px-1" icon={faUserAlt} />
          {`${
            seats.filter((seat: ISeat) => !seat.booked).length || 0
          }  persons`}
        </div>
        {isCalendarVisible && (
          <div className="flex justify-center">
            <Calendar
              onChange={(e: Date) => {
                onChange(e);
                setIsCalendarVisible(!isCalendarVisible);
              }}
              value={value}
            />
          </div>
        )}
        <div className="timepicker-ui" ref={tmRef} style={{ display: "none" }}>
          <input
            type="test"
            className="timepicker-ui-input"
            defaultValue={inputValue}
            ref={inputFile}
          />
        </div>
        <ImageMarker
          src="https://foyr.com/learn/wp-content/uploads/2022/09/how-to-design-restaurant-floor-plan.png"
          markers={seats}
          onAddMarker={(marker: Marker) => {
            let selectedSeat: ISeat | null = null;
            importedSeats.forEach((seat: ISeat) => {
              if (
                seat.top.valueOf() - 4 <= marker.top.valueOf() &&
                seat.top.valueOf() + 4 >= marker.top.valueOf() &&
                seat.left.valueOf() - 4 <= marker.left.valueOf() &&
                seat.left.valueOf() + 4 >= marker.left.valueOf() &&
                !seat.booked
              ) {
                selectedSeat = seat;
              }
            });
            if (
              selectedSeat &&
              seats.filter((seat: ISeat) => seat.id === selectedSeat?.id)
                ?.length === 0
            ) {
              setSeats([...seats, selectedSeat]);
            } else if (
              selectedSeat &&
              seats.filter(
                (seat: ISeat) => seat.id === selectedSeat?.id && !seat.booked
              )?.length === 1
            ) {
              setSeats(
                seats.filter((marker: ISeat) => marker.id !== selectedSeat?.id)
              );
            }
          }}
          markerComponent={CustomMarker}
        />
        {seats.filter((seat: ISeat) => !seat.booked).length > 0 && (
          <div className="flex justify-center">
            <Link
              to={`checkout`}
              className="rounded-full bg-orange-400 flex justify-center py-2 my-5 items-center w-full sm:w-[50%]"
            >
              Reserve
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
